import type {
    ResolverContext,
    ExportItemResult,
    QueryItemExportArgs,
    ExportFormatType,
} from 'owa-graph-schema';
import { getRoutingKeyForMailboxInfo } from 'owa-files-url-common';
import type { FilesUrlData } from 'owa-files-url/lib/schema/FilesUrlData';
import { getUrlDataForUserIdentity } from 'owa-files-url/lib/utils/getUrlDataForUserIdentity';
import type { MailboxInfo } from 'owa-client-types';

export const itemExportWeb = async (
    _parent: any,
    args: QueryItemExportArgs,
    _context: ResolverContext,
    _info: any
): Promise<ExportItemResult> => {
    const { itemId, exportFormatType, mailboxInfo } = args;
    try {
        const blob = await downloadBlob(itemId, exportFormatType, mailboxInfo);
        const blobAsString = await blob.text();
        return {
            itemId,
            successful: true,
            blobType: blob.type,
            data: btoa(blobAsString),
        };
    } catch (_e) {
        return {
            itemId,
            successful: false,
        };
    }
};

async function downloadBlob(
    itemId: string,
    exportFormatType: ExportFormatType,
    mailboxInfo: MailboxInfo
) {
    // EML is the default output format. If MSG is passed, then add to the qstring
    const formatTypeValue = exportFormatType === 'MSG' ? '&outputFormat=1' : '';
    const urlData: FilesUrlData | undefined = getUrlDataForUserIdentity(mailboxInfo);
    const downloadUrl = `${urlData?.downloadUrlBase}${getRoutingKeyForMailboxInfo(
        mailboxInfo
    )}/service.svc/s/DownloadMessage?id=${encodeURIComponent(itemId)}${formatTypeValue}&token=${
        urlData?.downloadToken
    }`;

    const response: Response = await fetch(downloadUrl);
    if (!response.ok) {
        throw new Error('downloadEmlBlob failed to download message as EML file');
    }

    const blob = await response.blob();
    return blob;
}
